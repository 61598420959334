import React from 'react';
import Lottie from 'react-lottie-player';
import { useRouter } from 'next/router';

import { Text } from '@/components/typographies';
import { COLORS } from '@/styles/colors';
import { Button } from '@/components/forms';
import errorAnimation from '@/assets/lotties/lottie_caution.json';
import { NavigationBar, StickyBottomView } from '@/components/useCases';
import Template from '@/components/useCases/Template';

import LeftArrow from '../../assets/icons/ic_arrow_left_regular.svg';
import * as styles from './styles';

const Error = () => {
  const router = useRouter();
  const APP_LINK = `https://goodoc.onelink.me/7srm?af_dp=goodoc://app/untact/intro?needSignedIn=true`;

  return (
    <Template.Page className={styles.errorContainer}>
      <NavigationBar
        leftComponent={
          <LeftArrow
            className={styles.backIcon}
            width={24}
            height={24}
            color={COLORS.GRAY_90}
            onClick={() => router.back()}
            alt="굿닥 비대면진료 뒤로가기"
          />
        }
      />
      <div className={styles.topContainer}>
        <div className={styles.titleContainer}>
          <Text type="heading3_700" color={COLORS.GRAY_100}>
            페이지를 찾지 못했어요
          </Text>
          <Text type="body1_400" color={COLORS.GRAY_80} className={styles.title}>
            {`주소가 잘못 입력 되었거나 변경 또는 삭제되어\n화면을 불러오지 못했어요.\n입력하신 주소를 한 번 더 확인해 주세요.`}
          </Text>
        </div>
        <Lottie loop play animationData={errorAnimation} className={styles.errorIcon} />
      </div>
      <StickyBottomView>
        <Button
          styleType="blueFilled"
          size="large"
          className={styles.footerButton}
          onClick={() => router.push(APP_LINK)}
        >
          굿닥 앱에서 비대면 진료 받기
        </Button>
      </StickyBottomView>
    </Template.Page>
  );
};

export default Error;
