import { css } from '@emotion/css';

import { COLORS } from '@/styles/colors';

export const errorContainer = css`
  background-color: ${COLORS.WHITE};
`;

export const backIcon = css`
  cursor: pointer;
`;

export const topContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 24px;
  flex: 1;
`;

export const titleContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;
  padding: 48px 0px 36px;
`;

export const title = css`
  white-space: pre-line;
  text-align: center;
`;

export const errorIcon = css`
  width: 240px;
  height: 182px;
`;

export const footerButton = css`
  width: 100%;
`;
